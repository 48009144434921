import { RichTextEditor, TEXT_EDITOR_MODES, FileInput, Notification, STATUS_TYPES } from 'dyl-components';
import React from 'react';
import { Controller } from 'react-hook-form';
import { Form, Icon, ListItem, Divider, Popup, Segment, Input } from 'semantic-ui-react';
import { useDispatch } from 'react-redux';
import CategorizedTemplates from './CategorizedTemplates';
import FileUtils from 'utils/FileUtils';
import { handleChangeTemplate, SIGNATURE_OPTIONS, determineSignature } from 'utils/EmailFormUtils';

const EmailTask = ({
    editorRef = React.createRef(),
    dropzoneRef = React.createRef(),
    control,
    trigger,
    description,
    subject,
    body,
    attachments = [],
    onSelectTemplate,
    editorMode,
    setEditorMode,
	setValue,

    signature,
	userSignature,
	companySignature,
	isDisabledSignature,

    isAllowedToModify = true
}) => {
    const dispatch = useDispatch();
    const onSwitchToHTML = () => {
        setEditorMode(TEXT_EDITOR_MODES.HTML);
    }

    const onSwitchToRichText = () => {
        setEditorMode(TEXT_EDITOR_MODES.RICH_TEXT);
    }

    return (
        <React.Fragment>
            <ListItem
                icon={
                    <Icon name='mail' color='black' />
                }
                description={
                    <Controller
                        name='subject'
                        defaultValue={subject || ''}
                        control={control}
                        rules={{
                            required: {
                                message: 'This field is required',
                                value: true
                            }
                        }}
                        render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                            <Form.Input
                                label='Subject'
                                onChange={onChange}
                                name={name}
                                value={value}
                                error={error && error.message && {
                                    content: error.message,
                                    pointing: 'below'
                                }}
                                required
                                placeholder="Type email subject"
                                readOnly={!isAllowedToModify}
                            />
                        )}
                    />
                }
            />
			<ListItem
				className='EmailThread__Row--input'
				description={
					<Form.Field>
						<Form.Field label={
							<label>
								<Icon name='fa-solid fa-pen' color='black' /> Signature {userSignature === null && companySignature === null && <Popup trigger={<Icon name='fa-solid fa-circle-info' color='blue' />} content='Signature needs to be configured' inverted basic position='right center' />}
							</label>
						} />
						<Controller
							name='signature'
							control={control}
							rules={{
								required: {
									message: 'This field is required',
									value: true
								}
							}}
							render={({ field: { onChange, value, name }, fieldState: { error } }) =>
								<Form.Group style={{ marginTop: "0.75em" }}>
									{SIGNATURE_OPTIONS.map((option, id) => (
										<Form.Radio
											key={option.key}
											label={<label style={{ paddingLeft: '1.4em' }}>{option.text}</label>}
											value={option.value}
											checked={(value || signature || determineSignature(userSignature || companySignature) ) === option.value}
											disabled={isDisabledSignature[id]}
											onChange={() => { setValue("signature", option.value ); trigger("signature"); onChange({ target: { name: "signature_preference", value: option.value } }) }}
										/>
									))}
								</Form.Group>
							}
						/>
					</Form.Field>
				}
			/>
            <Divider />
            <ListItem
                className='Task__description'
                icon={
                    <Icon name='align left' color='black' />
                }
                description={(
                    <Controller
                        name='body'
                        control={control}
                        defaultValue={description || ''}
                        render={({ field: { onChange, value } }) => (
                            <Form.Field
                                control={RichTextEditor}
                                label='Body'
                                onChange={(_, { value: new_value }) => { onChange({ target: { name: 'description', value: new_value } }) }}
                                value={value || body || ''}
                                toolbarClassName={"description_toolbar"}
                                allowSwitchToHTML
                                onSwitchToHTML={onSwitchToHTML}
                                onSwitchToRichText={onSwitchToRichText}
                                mode={editorMode}
                                editorRef={editorRef}
                                size='small'
                                disabled={!isAllowedToModify}
                                otherControls={[
                                    <CategorizedTemplates
										onSelectTemplate={(templateId) => handleChangeTemplate(templateId, setValue, dispatch, trigger, userSignature, companySignature)}
                                        type='Email'
                                    />
                                ]}
                            />
                        )}
                    />
                )}
            />
            <ListItem
                icon={
                    <Icon name='attach' color='black' />
                }
                description={(
                    <Controller
                        name="attachments"
                        defaultValue={attachments || []}
                        control={control}
                        rules={{
                            validate: {
                                max_size: value => {
                                    return FileUtils.isBelowFileSizeLimit(value, "email") || `Total size exceeds ${FileUtils.getMaxFileSize("email")}MB`;
                                }
                            }
                        }}
                        render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                            <Form.Field
                                control={Input}
                                className='Task__attachments-section'
                                disabled={!isAllowedToModify}
                                error={error && error.message && {
                                    content: error.message,
                                    pointing: 'below'
                                }}
                            >
                                <Segment style={{width: '100%'}}>
                                <div>
                                    <Icon onClick={() => { dropzoneRef.current.open(); }} color='blue' name='plus circle' link /> <b>Attach {value.length > 0 && 'more '}files</b>
                                </div>
                                <div className='Task__attachments'>
                                    <FileInput
                                        onChange={(_, { value: new_value }) => {
                                            onChange({
                                                target: {
                                                    name, value: [
                                                        ...value,
                                                        ...new_value.filter(new_file => (
                                                            value.findIndex(file => file.path === new_file.path) === -1
                                                        ))
                                                    ]
                                                }
                                            });
                                        }}
                                        onReject={(rejected) => {
                                            if (rejected.length > 0) {
                                                Notification.alert("File type must be .pdf.", STATUS_TYPES.ERROR, true);
                                            }
                                        }}
                                        onRemove={(_, { value }) => { onChange({ target: { name, value } }); }}
                                        files={value}
                                        name="files"
                                        accept="application/pdf"
                                        icon="file pdf outline"
                                        size="mini"
                                        dropzoneRef={dropzoneRef}
                                        showIcon />
                                </div>
                                <i>Remaining: {FileUtils.getRemainingSize(value, "email")} MB </i> {error?.message && `(${error.message})`}
                                </Segment>
                            </Form.Field>
                        )}
                    />
                )}
            />
        </React.Fragment>
    )
}

export default EmailTask;
